/* Modules */
import React from "react";
import ReactDOM from "react-dom";

/* Service Worker */
import registerServiceWorker from "./registerServiceWorker";

/* Styles */
import "normalize.css";

/* Async Function */
import asyncComponent from "./helpers/AsyncFunc";
import GAnalalytics from "react-ga";

GAnalalytics.initialize("UA-125024202-1");
/* Components */
const DashApp = asyncComponent(() => import("./dashApp"));

ReactDOM.render(<DashApp />, document.getElementById("root"));

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./dashApp.js", () => {
    const NextApp = require("./dashApp").default;
    ReactDOM.render(<NextApp />, document.getElementById("root"));
  });
}

registerServiceWorker();
